(function($) {
  var integrationsSlider = false;
  function integrationSlider(){
      //if($(window).width() < 992){
          if( !integrationsSlider && $('.integrations-list--slider').length ){
            $('.integrations-list--slider').slick({
              mobileFirst: true,
              draggable: false,
              arrows: true,
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
              adaptiveHeight: false,
              infinite: false,
              prevArrow: '<a href="#" class="slick-prev"><svg class=""><use xlink:href="#slider-arrow" /></svg></a>',
              nextArrow: '<a href="#" class="slick-next"><svg class=""><use xlink:href="#slider-arrow" /></svg></a>',
              responsive: [
               {
                 breakpoint: 480,
                 settings: {
                   slidesToShow: 2,
                   slidesToScroll: 1
                 }
               },
               {
                 breakpoint: 720,
                 settings: {
                   slidesToShow: 3,
                   slidesToScroll: 1
                 }
               }
             ]
            });
            integrationsSlider = true;
          }
      /*} else if($(window).width() >= 992){
          if( integrationsSlider && $('.integrations-list--slider').length ){
              $('.integrations-list').slick('unslick');
              integrationsSlider = false;
          }
      }*/
  }

  $(document).ready(function() {

    $(document).on('click', '.open-int-sidebar', function(e) {
      $('.int-categories-sidebar').addClass('active');
      $('body').css('overflow-y', 'hidden');
      e.preventDefault();
    });
    $(document).on('click', '.close-int-sidebar', function(e) {
      $('.int-categories-sidebar').removeClass('active');
      $('body').css('overflow-y', 'auto');
      e.preventDefault();
    });
    $(document).on('click', '.int-categories-sidebar__group h5', function(e) {
      $(this).parent().toggleClass('active');
    });

    // TODO
    // - kun klikkaa muualla, pitää dropdownista poistaa fs-open
    /*$(document).on('click', '.more-facets-wrap .fs-label-wrap', function(e) {
      if ( $(this).parent().hasClass('fs-open') ) {
        $(this).parent().removeClass('fs-open');
        $(this).parent().find('.fs-dropdown').addClass('hidden');
      } else {
        $(this).parent().addClass('fs-open');
        $(this).parent().find('.fs-dropdown').removeClass('hidden');
      }
    });*/

    setTimeout(function(){
      $('.int-categories-sidebar .facetwp-checkbox').each(function() {
        if ( $(this).hasClass('checked') ) {
          var this_val = $(this).attr('data-value');
          //var this_text = $(this).clone().children().remove().end().text();
          var this_text = $(this).find('.facetwp-display-value').text();
          var this_toggle = '<div class="filter-toggle" data-value="' + this_val + '">' + this_text + '</div>';
          $('.integrations-active-filters').append(this_toggle);
        }
      });
    }, 500);

    $(document).on('click', '.all-integrations-toggle', function(e) {
      if ( 'integraatiot' == FWP_HTTP.uri ) {
        e.preventDefault();
        FWP.reset();
        $('.integrations-active-filters').html('');
      }
    });

    // Add or remove filter toggles when clicking facets inside more filters dropdown
    $(document).on('click', '.int-categories-sidebar .facetwp-checkbox', function(e) {
      var this_val = $(this).attr('data-value');
      //var this_text = $(this).clone().children().remove().end().text();
      var this_text = $(this).find('.facetwp-display-value').text();
      var toggle_selector = $('.filter-toggle[data-value="' + this_val + '"]');
      var this_toggle = '<div class="filter-toggle" data-value="' + this_val + '">' + this_text + '</div>';
      if ( $(this).hasClass('checked') && !toggle_selector.length ) {
        $('.integrations-active-filters').append(this_toggle);
      } else {
        toggle_selector.remove();
      }

      $('.more-facets-wrap').removeClass('fs-open');
    });

    // Toggle checkboxes off from more filters dropdown when clicking visible filter toggles
    $(document).on('click', '.filter-toggle', function(e) {
      var this_val = $(this).attr('data-value');
      var facet_selector = $('.facetwp-checkbox[data-value="' + this_val + '"]');
      facet_selector.click();
      //$('.more-facets-wrap').removeClass('fs-open');
    });

    // slider init
    integrationSlider();

    $('#int-slider .slides').slick({
      draggable: false,
      arrows: false,
      infinite: false,
      mobileFirst: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      dots: false,
      adaptiveHeight: false,
      asNavFor: '#int-carousel .slides'
    });
    $('#int-carousel .slides').slick({
     slidesToShow: 6,
     slidesToScroll: 1,
     asNavFor: '#int-slider .slides',
     dots: false,
     arrows: false,
     centerMode: false,
     focusOnSelect: true,
     responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3
        }
      }
    ]
    });

    /*$('.js-int-select-contacts').on('click', function(e) {
      e.preventDefault();
      if ( $(this).hasClass('select-on') ) {
        $('.js-int-select-contacts').removeClass('select-on')
        $('.int-select-contact').removeClass('visible');
        $('.int-request-container').removeClass('active');
        $('.int-request-container .js-int-select-contacts').text('Valitse tilitoimistot');
      } else {
        $('.js-int-select-contacts').addClass('select-on')
        $('.int-select-contact').addClass('visible');
        $('.int-request-container').addClass('active');
        $('.int-request-container .js-int-select-contacts').text('Peruuta');
      }
    });

    $(document).on('click', '.js-int-select-contact', function(e) {
      e.preventDefault();
      var this_id = $(this).attr('data-aoid');
      var this_contact_text = $(this).attr('data-title');
      var this_contact_email = $(this).attr('data-email');
      if ( $(this).hasClass('selected') ) {
        $(this).removeClass('selected');
        $(this).parent().parent().removeClass('selected');
        $('.contact-toggle[data-aoid="' + this_id + '"]').remove();
      } else {
        $(this).addClass('selected');
        $(this).parent().parent().addClass('selected');
        var this_toggle = '<div class="contact-toggle" data-email="' + this_contact_email + '" data-aoid="' + this_id + '">' + this_contact_text + '</div>';
        $('.int-modal-request-selected').append(this_toggle);
      }
      if ( $('.int-modal-request-selected .contact-toggle').length < 1 ) {
        $('.int-request-container .js-int-contact').hide();
        $('.int-request-container .js-int-no-contact').show();
      } else {
        $('.int-request-container .js-int-contact').show();
        $('.int-request-container .js-int-no-contact').hide();
      }
      mpUpdateFormEmails();
    });

    $(document).on('click', '.contact-toggle', function(e) {
      var this_val = $(this).attr('data-value');
      var this_id = $(this).attr('data-aoid');
      var this_email = $(this).attr('data-email');
      var contact_selector = $('.js-int-select-contact[data-aoid="' + this_id + '"]');
      contact_selector.click();
      //mpRemoveFormEmails(this_email);
    });*/

    /*function mpUpdateFormEmails() {
      var selected_unique_emails = [];
      $('.int-modal-request-selected .contact-toggle').each(function() {
        var this_email = $(this).attr('data-email');
        if ( jQuery.inArray( this_email, selected_unique_emails) < 0 ) {
          selected_unique_emails.push(this_email);
        }
      });

      var emails_string = selected_unique_emails.join(",");
      $('#input_5_14').val(emails_string); // Live input_5_14,
      //$('#input_20_14').val(emails_string); // Local  input_20_14
      //console.log($('#input_5_14').val());
      if ( selected_unique_emails.length == 0) {
        $.magnificPopup.close();
      }
    }

    $('.js-int-contact').magnificPopup({type:'inline'});
    $('.js-int-no-contact').on('click', function(e) {
      e.preventDefault();
      return false;
    });*/

    // Gravity forms analytics trigger
    /*jQuery(document).bind("gform_confirmation_loaded", function(event, formID) {

      window.dataLayer = window.dataLayer || [];
      var numEmails = $('.int-modal-request-selected .contact-toggle').length;
      if ( numEmails > 1 ) {
        for (var i = 1; i < numEmails; i++) {
          window.dataLayer.push({
            event: "formSubmission",
            formID: formID
          });
          console.log(window.dataLayer);
          console.log('form-id:' + formID);
          console.log('i: ' + i);
        }
      }


    });*/

  });
  $(window).on('resize', function(){
    integrationSlider();
  });
})(jQuery);
